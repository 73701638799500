import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { StaticImage as Img } from "gatsby-plugin-image";
import { Link } from "~components";
import Hero from "./style";
// import { useFathom } from "@raae/gatsby-plugin-fathom";
export default function HeroSection() {
  // const { trackGoal, trackPageview } = useFathom();
  return (
    <Hero backgroundColor="#f7f7f7">
      <Container>
        <Row className="align-items-center justify-content-center">
          {/* Welcome content Area */}
          <Col className="col-xxl-6 col-lg-7 col-md-9 col-xs-11 order-2 order-lg-1">
            <Hero.Content>
              <Hero.SubTitle fontColor="#ff7272" as="h3">
                {""}
              </Hero.SubTitle>
              <Hero.Title as="h1">
                Wer zufrieden ist,{" "}
                <br className="d-none d-xs-block d-lg-none d-xl-block" />
                kommt gerne wieder!
              </Hero.Title>
              {/* <Hero.Text fontColor="rgba(38, 39, 41, 0.7)">
                When, while lovely valley teems with vapour around meand{" "}
                <br className="d-none d-md-block" />
                meridian sun strikes the upper impenetroble.
              </Hero.Text> */}
              <Hero.ButtonGroup>
                {/* <Hero.Button className="btn-red 🌱" as={Link} to="/">
                  Watch Video
                </Hero.Button> */}
                <Hero.Button
                  className="btn-green 🌱"
                  as={Link}
                  to="https://guest.myfeedoo.de/demo"
                  // onClick={trackGoal("XODH5C2A", 0)}
                >
                  Demo
                </Hero.Button>
                <Hero.Button
                  className="btn-purple"
                  as={Link}
                  to="https://business.myfeedoo.de/QrCode"
                  // onClick={trackGoal("ONNA3TZQ", 0)}
                >
                  Jetzt Starten
                </Hero.Button>
              </Hero.ButtonGroup>
            </Hero.Content>
          </Col>
          {/*/ .Welcome Content Area */}
          {/*/ .Welcome img Area */}
          {/* <Col
            xs="12"
            className="col-xxl-6 col-lg-5 col-md-8 col-xs-10 order-1 order-lg-2"
          >
            <Hero.ImageGroup>
              <Hero.ImageOne>
                <Img
                  className="w-100"
                  src="../../assets/image/home-app/flyer.png"
                  alt="app"
                  layout="fullWidth"
                  placeholder="blurred"
                />
              </Hero.ImageOne>
              <Hero.ImageTwo>
                <Img
                  className="w-100"
                  src="../../assets/image/home-app/myfeedoo_mockup_iphone_rotated.png"
                  alt="app-img"
                  layout="fullWidth"
                  placeholder="blurred"
                />
              </Hero.ImageTwo>
            </Hero.ImageGroup>
          </Col> */}
          {/*/ .Welcome img Area */}
        </Row>
      </Container>
    </Hero>
  );
}
