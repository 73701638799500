import React from "react";
import SectionTitle from "../ContentOne/components/SectionTitle";
import { StaticImage as Img } from "gatsby-plugin-image";
import { Col, Container, Row } from "react-bootstrap";
import Widget from "../ContentOne/components/Widget";
import Content from "../ContentOne/style";
export default function AboutFeedoo() {
  return (
    <Content backgroundColor="#f2f5fb" id={"section1"}>
      <Container>
        <Row className="align-items-center justify-content-center">
          {/* about-us Content */}
          <Col className="col-xxl-6 col-lg-7 col-md-9 col-xs-10 order-2 orde-lg-1">
            <Content.Box mbLG="60px" mb="30px">
              <SectionTitle
                subTitle="Warum Feedoo?"
                title="Ehrliches Kundenfeedback."
                text="Wir glauben an ehrliches Feedback für die Verbesserung vom Service und Angebot. Feedoo ist einfache digitale Lösung für direktes und anonymes Feedback."
                subTitleProps={{ mb: "20px" }}
                titleProps={{ mb: "10px" }}
              />
            </Content.Box>
            <Content.Box>
              <Row className=" justify-content-center">
                <Col className="col-lg-6 col-xs-6">
                  <Widget
                    icon="far fa-envelope"
                    title="Direkt"
                    text="Das Feedback geht dirket an Ihnen und wird nicht, sowie bei Google and Co, auf Internet angezeigt."
                  />
                </Col>
                <Col className="col-lg-6 col-xs-6">
                  <Widget
                    icon="fas fa-user-shield"
                    title="Anyonm"
                    text="Ihre Gäste bleiben anonym und können im vollen Vertrauen ihre Wünsche äußern."
                  />
                </Col>
              </Row>
            </Content.Box>
          </Col>
          {/*/ .about-us Content */}
          <Col
            xs="12"
            className="col-xxl-6 col-lg-5 col-md-8 col-xs-10 order-1 order-lg-2"
          >
            <Content.ImageGroup>
              <Img
                src="../../assets/image/home-app/mockup_iphone_send_feedback_rotated.png"
                alt="content"
                layout="fullWidth"
                placeholder="blurred"
              />
              <Content.ImageShape>
                <Img
                  src="../../assets/image/home-app/magenta-shape.png"
                  alt="content"
                  layout="fullWidth"
                  placeholder="blurred"
                />
              </Content.ImageShape>
            </Content.ImageGroup>
          </Col>
        </Row>
      </Container>
    </Content>
  );
}
