import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SectionTitle from "./components/SectionTitle";
import Widget from "./components/widget";
import { StaticImage as Img } from "gatsby-plugin-image";
import About from "./style";
export default function AboutSection() {
  return (
    <About>
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xl-6 col-lg-8 col-md-9 col-xs-10 text-center">
            <SectionTitle
              subTitle="Kundenfeedback und mehr für"
              title="Veranstaltungsorte"
              titleProps={{ mb: "50px" }}
              subTitleProps={{ mb: "15px" }}
            />
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center">
          <Col className="col-xxl-3 col-lg-4 col-md-6 col-sm-8 col-xs-9 order-2 order-lg-1">
            <Widget
              directionXS="row-reverse"
              title="Kundenfeedback"
              icon="fa fa-comment-dots"
              text="Direkt und anonym
              für die
              Verbesserung des Services "
              link="#section1"
            />
            <Widget
              directionXS="row-reverse"
              title="Corona-Anmeldung"
              icon="fa fa-door-open"
              text="Integrierte Registrierung über die Tools Ihrer Wahl
            
              (z.B. Luca, Corona-Warn-App) "
              link="#section1"
            />
            {/* <Widget
              directionXS="row-reverse"
              title="Information"
              icon="fa fa-info-circle"
              text="Alle Informationen und Kontaktmöglichkeiten auf einer Seite"
              link="#section1"
            /> */}
          </Col>
          <Col
            xs="12"
            className="col-xxl-6 col-lg-4 col-md-8 col-xs-9 order-1 order-lg-2 text-center"
          >
            <About.ImageContent>
              <Img
                src="../../assets/image/home-app/mockup_iphone_straight_eventlocation.png"
                alt="content"
                // layout="fullWidth"
                placeholder="blurred"
                style={{ maxWidth: "250px", marginBottom: "20px" }}
              />
              <About.Shape>
                <Img
                  src="../../assets/image/home-app/magenta-shape.png"
                  alt="content"
                  layout="fullWidth"
                  placeholder="blurred"
                />
              </About.Shape>
            </About.ImageContent>
          </Col>
          <Col className="col-xxl-3 col-lg-4 col-md-6 col-sm-8 col-xs-9 order-3">
            <Widget
              directionXS="row"
              title="Agenda"
              icon="far fa-calendar-alt"
              text="Aktuelle und übersichtliche Liste der Kurse und Workshops
              für Handys "
              link="#section1"
            />
            <Widget
              directionXS="row"
              title="Information"
              icon="fa fa-info-circle"
              text="Alle Informationen und Kontaktmöglichkeiten auf einer Seite"
              link="#section1"
            />
            {/* <Widget
              directionXS="row"
              title="Bestellungen"
              icon="fa fa-shopping-cart"
              text="Bequem
              mit Handy bestellen und
              bezahlen"
              link="#section1"
            /> */}
            {/* <Widget
              directionXS="row"
              title="Service rufen"
              icon="fa fa-concierge-bell"
              text="Ihre Gäste können sich melden und bleiben nicht unbemerkt "
              link="#section1"
            /> */}
          </Col>
        </Row>
      </Container>
    </About>
  );
}
