import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import Pricing from "./style";
import PricingCard from "./Components/Card";

const pricingValues = {
  cardOne: {
    monthly: {
      price: "Gratis",
    },
    yearly: {
      price: "Gratis",
    },
    features: [
      "Direktes Kundenfeedback",
      "Digitale Speise- und Getränkekarte",
      "Corona-Anmeldung",
      "Informations- und Kontaktseite",
    ],
  },
  cardTwo: {
    monthly: {
      price: 6,
    },
    yearly: {
      price: 5,
    },
    features: [
      "Digitale Speise und Getränke Karte",
      "Direktes Kundenfeedback",
      "Corona Anmeldung",
      "Gastätte Information",
    ],
  },
  cardThree: {
    monthly: {
      price: 28,
    },
    yearly: {
      price: 25,
    },
    features: [
      "Leistungen des Starterpakets",
      "Bestellfunktion",
      "Bezahlfunktion (Paypal)",
      "Servicepersonal-Ruf",
    ],
  },
};

export default function PricingSection({ ...rest }) {
  const [yearly, setYearly] = React.useState(true);
  const { cardOne, cardTwo, cardThree } = pricingValues;
  return (
    <Pricing backgroundColor="#f3f4f6" {...rest}>
      <Container>
        <Pricing.Box pb="40px" pbXL="70px">
          <Row className="align-items-end justify-content-center">
            <Col xs="12" className="col-xl-8 col-lg-7">
              <Pricing.Box mb="30px" className="text-center">
                <Pricing.Subtitle>Preise</Pricing.Subtitle>
                <Pricing.Title mb="20px" mbXL="0">
                  Starten Sie heute!
                </Pricing.Title>
              </Pricing.Box>
              <Pricing.Button className="pricing-btn">
                <label className="mb-3 mb-lg-0">Monatlich</label>
                <div className="toggle-btn form-switch mb-3 mb-lg-0">
                  <input
                    className="form-check-input btn-toggle price-deck-trigger"
                    checked={yearly}
                    type="checkbox"
                    onChange={() => setYearly(!yearly)}
                  />
                </div>
                <label className="mb-3 mb-lg-0">Jährlich</label>
                <Pricing.Box
                  as="h5"
                  backgroundColor="#82c4c3"
                  fontColor="#262729"
                  className="badge"
                >
                  20% SPAREN
                </Pricing.Box>
              </Pricing.Button>
            </Col>
          </Row>
        </Pricing.Box>
        <Row className="justify-content-center">
          {/* Single Table */}
          <Col xs="12" className="col-lg-4 col-md-6 col-sm-9 col-xs-10">
            <PricingCard
              cardTitle={"Starter"}
              backgroundColor={"#099a97"}
              amount={yearly ? cardOne.yearly.price : cardOne.monthly.price}
              yearlyPackage={yearly}
              features={cardOne.features}
            />
          </Col>
          {/* Single Table */}
          {/* <Col xs="12" className="col-lg-4 col-md-6 col-sm-9 col-xs-10">
            <PricingCard
              cardTitle={"Digitale Karte"}
              backgroundColor={"#a91261"}
              amount={yearly ? cardTwo.yearly.price : cardTwo.monthly.price}
              yearlyPackage={yearly}
              features={cardTwo.features}
            />
          </Col> */}
          {/* Single Table */}
          <Col xs="12" className="col-lg-4 col-md-6 col-sm-9 col-xs-10">
            <PricingCard
              cardTitle={"All-in-One"}
              backgroundColor={"#a91261"}
              amount={yearly ? cardThree.yearly.price : cardThree.monthly.price}
              yearlyPackage={yearly}
              features={cardThree.features}
            />
          </Col>
        </Row>
      </Container>
    </Pricing>
  );
}
