import React from "react";
import ServicesCard from "./Components/Card";
import Service from "./style";
import SectionTitle from "./Components/SectionTitle";
import { Col, Container, Row } from "react-bootstrap";
export default function ServicesSection() {
  return (
    <Service>
      <Container className="container">
        <Row className="row justify-content-center">
          <Col className="col-xl-8 text-center">
            <SectionTitle
              // subTitle="Das beste"
              title="Starten Sie heute"
              titleProps={{ mb: "40px", mbLG: "75px" }}
              subTitleProps={{ mb: "20px" }}
            />
          </Col>
        </Row>
        <Row className="row justify-content-center">
          {/*/ .Single Services */}
          <Col xs="12" className="col-xl-3 col-lg-4 col-md-6 col-xs-8">
            <ServicesCard
              title="Registrieren"
              icon="fas fa-sign-in-alt"
              iconColor="#be79df"
              text="Die freie Registrierung dauert ein paar Sekunden. Wir senden anschliessend per Email ihren persönlichen QR code"
            />
          </Col>
          {/*/ .Single Services */}
          {/* Single Services */}
          <Col xs="12" className="col-xl-3 col-lg-4 col-md-6 col-xs-8">
            <ServicesCard
              title="QR code drucken"
              icon="fas fa-qrcode"
              iconColor="#ff7272"
              text="Drucken Sie den QR code und plazieren den am besten wo alle Gästen sehen können. Wenn Sie Tische haben, dann auch gerne an jeden Tisch."
            />
          </Col>
          {/*/ .Single Services */}
          {/* Single Services */}
          <Col className="col-xl-3 col-lg-4 col-md-6 col-xs-8">
            <ServicesCard
              title="Starten!"
              icon="fas fa-play"
              iconColor="#099a97"
              text="Jetzt fangen Ihre Gäste an Feedack zu geben. Sie werden wochentlich benachrichtigt über neues Feedback."
            />
          </Col>

          {/*/ .Single Services */}
        </Row>
      </Container>
    </Service>
  );
}
