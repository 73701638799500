import React from "react";
import Gastronomie from "../sections/About/Gastronomie";
import Workshops from "../sections/About/Workshops";
import ContentSectionOne from "../sections/AboutFeedoo/AboutFeedoo";
import Hero from "../sections/Hero/HeroSection";
import Pricing from "../sections/Pricing/PricingSection";
import Services from "../sections/Services/ServicesSection";
import FooterFour from "../sections/FooterFour/FooterFour";
import AboutFeedoo from "../sections/AboutFeedoo/AboutFeedoo";
import SEO from "~components/Seo/seo.js";

import { PageWrapper } from "~components";
const header = {
  headerClasses:
    "site-header site-header--menu-end light-header site-header--sticky",
  containerFluid: false,
};

export default function Marketing() {
  return (
    <PageWrapper headerConfig={header}>
      <SEO />
      <Hero />
      <AboutFeedoo />
      <Services />
      <Gastronomie />
      <Workshops />
      {/* <ContentSectionOne /> */}
      {/* <Pricing /> */}
      <FooterFour />
    </PageWrapper>
  );
}
